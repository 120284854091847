@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

/* With font-size: 62.5% we are setting 1rem as 10px with the default browser font-size as 16px */
:root {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0C2845;
}

#cartagena2022{
  font-family: -apple-system, 'Open Sans', sans-serif !important;
}

.cartagena2022{
  font-family: -apple-system, 'Open Sans', sans-serif !important;
}

.swalbutton {
  background: #EAC36A;
  border-radius: 5px;
  border: 0;
  padding: 8px 16px;
  color: #000;
  cursor: pointer;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
}


.swalbutton:disabled {
    background-color: rgba(60,93,150,0.62);
  }

.swalbutton:focus {
  outline: none;
}


.swalbutton:hover {
  background-color: rgb(239 209 142);
}

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  text-decoration: none;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}

.whatsapp_float:focus {
  color: #FFF;
  opacity: 0.9;
}

.whatsapp_float:hover {
  color: #FFF;
  opacity: 0.9;
}